import React, { useContext } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Menu } from "semantic-ui-react";
import { AppContext } from "./layout";
import { device } from "../utilities/media_sizes";

const StyledMenu = styled(Menu)`
  border: 0 !important;
  text-align: center;
  box-shadow: none !important;
  font-size: 20px !important;
  a {
    width: 100vw !important;

    @media ${device.tablet} {
      width: 100% !important;
    }
  }
`;

const LinkedItem = ({ children, ...props }) => (
  <Menu.Item as={Link} activeClassName="active" {...props}>
    {children}
  </Menu.Item>
);

export default function MenuWrapper() {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allGoogleMenuPagesSheet {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);

  const menuItems = data.allGoogleMenuPagesSheet.edges;
  const { setSidebarVisible } = useContext(AppContext);

  return (
    <StyledMenu vertical fluid borderless>
      <LinkedItem to="/" exact>
        <p onClick={() => setSidebarVisible()}>Click me</p>
      </LinkedItem>
      {menuItems.map(({ node: { slug, title } }, i) => (
        <LinkedItem to={`/${slug}`}>{title}</LinkedItem>
      ))}
      <LinkedItem to="/page-2">Second Page</LinkedItem>
      <LinkedItem to="/404">404 Example Page</LinkedItem>
    </StyledMenu>
  );
}
