import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Container, Icon } from "semantic-ui-react";
import { AppContext } from "./layout";

const Wrapper = styled.div`
  background: rgb(66, 82, 255);
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 80px;
  @media (max-width: 600px) {
    padding-top: 16px;
    height: 160px;
    flex-direction: column;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  i {
    color: white;
    font-size: 30px;
  }
`;

const Header = ({ siteTitle }) => {
  const { setSidebarVisible } = useContext(AppContext);

  return (
    <Wrapper>
      <Container>
        <HeaderWrapper>
          <img src="https://rayloimages.s3.eu-west-2.amazonaws.com/logo.png" />
          <h1 style={{ padding: "1rem 0", marginBottom: "2rem" }}>
            <Link style={{ color: "white" }} to="/">
              {siteTitle}
            </Link>
          </h1>
        </HeaderWrapper>
      </Container>
    </Wrapper>
  );
};

export default Header;
