import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { Container, Grid, Sidebar, Segment } from "semantic-ui-react";
import MenuWrapper from "./menu";
import Header from "./header";

import "semantic-ui-less/semantic.less";
import { SidebarWrapper } from "./sidebar";

export const AppContext = createContext();

const Layout = ({ children, data }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const toggleSidebarVisible = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const state = {
    sidebarVisible: sidebarVisible,
    setSidebarVisible: toggleSidebarVisible,
  };

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <AppContext.Provider value={state}>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: "description",
                content: "Vote for your 3 favourite images",
              },
              { name: "keywords", content: "sample, something" },
            ]}
          />
          <Sidebar.Pushable as={Segment}>
            <SidebarWrapper />
            <Sidebar.Pusher
              dimmed={sidebarVisible}
              style={{ minHeight: "100vh" }}
            >
              <Header siteTitle={data.site.siteMetadata.title} />

              <Container>
                <Grid relaxed stackable>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    {children}
                  </Grid.Column>
                </Grid>
              </Container>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </AppContext.Provider>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
