import React, { useContext } from "react";
import styled from "styled-components";
import { Menu, Sidebar, Icon } from "semantic-ui-react";
import { AppContext } from "./layout";
import MenuWrapper from "./menu";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CloseButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 32px;
  cursor: pointer;
  i {
    font-size: 32px;
  }
`;

const SidebarContent = styled.div``;

export const SidebarWrapper = () => {
  const { sidebarVisible, setSidebarVisible } = useContext(AppContext);
  return (
    <Sidebar visible={sidebarVisible} animation="overlay" as={Menu} width="very wide" animation="slide along">
      <Wrapper>
        <CloseButtonWrapper onClick={() => setSidebarVisible()}>
          <Icon name="close" />
        </CloseButtonWrapper>
        <SidebarContent>
          <MenuWrapper />
        </SidebarContent>
      </Wrapper>
    </Sidebar>
  );
};
